/*
Based on html5doctor.com Reset Stylesheet v1.6.1
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  outline: 0;
  font-size: 14px;
  font-family: "Muli", sans-serif;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 22px; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

a {
  color: black;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

input,
select {
  vertical-align: middle; }

button:focus {
  outline: none; }

.grid-row:after {
  display: block;
  clear: both;
  content: ""; }

.grid-container {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  max-width: 1200px; }

.grid-row {
  margin-left: -1%;
  margin-right: -1%; }

[class^="grid-col-"] {
  float: left;
  padding-left: 1%;
  padding-right: 1%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.grid-col-1 {
  width: 8.33333%; }

.grid-col-2 {
  width: 16.66667%; }

.grid-col-3 {
  width: 25%; }

.grid-col-4 {
  width: 33.33333%; }

.grid-col-5 {
  width: 41.66667%; }

.grid-col-6 {
  width: 50%; }

.grid-col-7 {
  width: 58.33333%; }

.grid-col-8 {
  width: 66.66667%; }

.grid-col-9 {
  width: 75%; }

.grid-col-10 {
  width: 83.33333%; }

.grid-col-11 {
  width: 91.66667%; }

.grid-col-12 {
  width: 100%; }

@media only screen and (max-width: 991px) {
  .grid-col-m-1 {
    width: 8.33333%; }
  .grid-col-m-2 {
    width: 16.66667%; }
  .grid-col-m-3 {
    width: 25%; }
  .grid-col-m-4 {
    width: 33.33333%; }
  .grid-col-m-5 {
    width: 41.66667%; }
  .grid-col-m-6 {
    width: 50%; }
  .grid-col-m-7 {
    width: 58.33333%; }
  .grid-col-m-8 {
    width: 66.66667%; }
  .grid-col-m-9 {
    width: 75%; }
  .grid-col-m-10 {
    width: 83.33333%; }
  .grid-col-m-11 {
    width: 91.66667%; }
  .grid-col-m-12 {
    width: 100%; } }

@media only screen and (min-width: 992px) {
  .grid-col-d-1 {
    width: 8.33333%; }
  .grid-col-d-2 {
    width: 16.66667%; }
  .grid-col-d-3 {
    width: 25%; }
  .grid-col-d-4 {
    width: 33.33333%; }
  .grid-col-d-5 {
    width: 41.66667%; }
  .grid-col-d-6 {
    width: 50%; }
  .grid-col-d-7 {
    width: 58.33333%; }
  .grid-col-d-8 {
    width: 66.66667%; }
  .grid-col-d-9 {
    width: 75%; }
  .grid-col-d-10 {
    width: 83.33333%; }
  .grid-col-d-11 {
    width: 91.66667%; }
  .grid-col-d-12 {
    width: 100%; } }

.background-grey {
  background: #f2f2f2; }

.l-vertical-space {
  margin-top: 20px;
  margin-bottom: 20px; }

.l-vertical-space-bottom {
  margin-bottom: 20px; }

.u-text-centre {
  text-align: center; }

@media only screen and (max-width: 991px) {
  .l-m-hidden {
    display: none; } }

@media only screen and (min-width: 992px) {
  .l-d-hidden {
    display: none; } }

.c-address {
  text-transform: capitalize; }

.c-address__map-pin {
  padding-right: 10px; }

.c-background {
  background: #f2f2f2; }

.c-banner {
  background: white;
  padding: 30px; }

.c-banner__content {
  text-align: center; }

.c-banner__heading {
  margin-bottom: 10px;
  text-align: center; }

.c-breadscrumbs__breadcrumb {
  display: inline-block;
  padding: 0 0 0 10px;
  height: 60px;
  line-height: 60px; }

.c-breadscrumbs__breadcrumb:after {
  content: ">";
  padding: 0 0 0 10px; }

.c-breadscrumbs__breadcrumb:last-child:after {
  content: "";
  padding: 0; }

.c-breadscrumbs__breadcrumb-link {
  cursor: pointer;
  text-decoration: underline; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.c-button {
  width: 100%;
  background: #231f20;
  color: white;
  border: none;
  text-transform: uppercase;
  line-height: 15px;
  padding: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  cursor: pointer; }

.c-button--loading {
  background: #dbdbdb;
  height: 45px; }

.c-button--loading::after {
  content: "";
  display: block;
  height: 15px;
  width: 15px;
  border: 2px solid #707070;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 700ms linear infinite;
  margin: 0 auto;
  position: relative; }

.c-button--secondary {
  background: white;
  border: 1px solid #54565e;
  color: #54565e; }

.c-cancel-my-order {
  margin-bottom: 50px; }

.c-cancel-my-order__cancel-panel-terms-and-conditions {
  display: inline-block;
  font-size: 0.9em;
  text-align: center;
  width: 100%; }

.c-cancel-my-order__return-to-my-orders {
  margin: auto;
  width: 300px; }

.c-close-cross {
  height: 20px;
  width: 20px; }

.c-divider {
  display: flex;
  font-family: "sans-serif";
  font-size: 0.8em;
  margin: auto;
  padding: 10px 0;
  text-align: center;
  width: 100px; }

.c-divider:before,
.c-divider:after {
  border-bottom: 2px solid #eeeded;
  content: "";
  flex: 1;
  margin: 0.6em 5px; }

.c-dummy-image {
  background: #f3f3f3; }

.c-header {
  align-items: center;
  display: flex;
  padding: 0 20px;
  margin: 15px 0;
  justify-content: space-between; }

.c-header__close {
  background: none;
  border: none;
  cursor: pointer;
  height: 20px;
  padding: 0; }

@media only screen and (min-width: 992px) {
  .c-header__close {
    display: none; } }

.c-heading-1 {
  font-family: "Playfair Display SC", serif;
  font-weight: 400;
  text-transform: uppercase; }

.c-heading-2 {
  font-family: "Playfair Display SC", serif;
  font-weight: 400;
  text-transform: uppercase; }

@media only screen and (max-width: 991px) {
  .c-heading-1 {
    font-size: 22.5px; }
  .c-heading-2 {
    font-size: 17.5px; } }

@media only screen and (min-width: 992px) {
  .c-heading-1 {
    font-size: 27px; }
  .c-heading-2 {
    font-size: 19px; } }

.c-horizontal-separator__line {
  width: 50px;
  height: 15px;
  border-bottom: 1px solid #dddddd;
  display: inline-block;
  font-size: 1px;
  vertical-align: top; }

.c-horizontal-separator__x {
  font-size: 26px;
  font-family: serif;
  display: inline-block;
  color: #606060; }

.c-label {
  display: block; }

.c-label__text {
  display: inline-block;
  margin-bottom: 5px; }

.c-list {
  padding-left: 30px; }

.c-list__item--tick {
  position: relative;
  list-style-type: none;
  margin: 20px 0; }

.c-list__item--tick:before {
  background: url(assets/tick.68928d46.svg) no-repeat;
  content: "";
  top: 5px;
  left: -30px;
  position: absolute;
  height: 100%;
  width: 20px; }

.c-panel {
  background: white;
  border: 1px solid rgba(192, 192, 192, 0.57);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05);
  padding: 20px;
  transition: opacity 0.2s ease-out;
  margin-bottom: 4px; }

.-content-inactive .c-panel {
  opacity: 0.5; }
  .-content-inactive .c-panel select,
  .-content-inactive .c-panel input {
    pointer-events: none; }

.-content-inactive .c-panel__active-content {
  display: none; }

@media only screen and (max-width: 991px) {
  .-content-inactive .c-panel {
    display: none; } }

.c-panel--with-header {
  padding-top: 0; }

.c-panel__header {
  background: #f3f3f3;
  margin: 0 -20px 20px -20px;
  padding: 10px 20px; }

.c-panel.-not-ready,
.-content-inactive .c-panel.-not-ready {
  opacity: 0; }

.c-my-order__panel {
  background: white;
  border: 1px solid rgba(192, 192, 192, 0.57);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 0; }

.c-my-order__column {
  padding: 20px; }

.c-order-item {
  clear: left;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px; }

.c-order-item__img {
  float: left;
  width: 23%; }

.c-order-item__info {
  float: left;
  width: 50%;
  margin-top: 15px; }

.c-order-item__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.c-order-item__designer {
  color: #707070; }

.c-order-item__price {
  width: 23%;
  float: right;
  text-align: right;
  margin-top: 30px; }

.c-order-items__total {
  display: flex;
  justify-content: space-between; }

.c-order-status {
  font-weight: bold;
  text-transform: uppercase; }

.c-order-status--cancelled {
  color: #bf2b27; }

.c-order-status--dispatched,
.c-order-status--packing {
  color: #d1a584; }

.c-order-status--refund_requested,
.c-order-status--refunded {
  color: #707070; }

.c-order-status--reserved {
  color: #4fa840; }

.c-order-totals {
  background: #f3f3f3;
  padding: 15px; }

.c-order-totals__heading {
  text-transform: uppercase;
  text-align: center; }

.c-order-totals__item {
  float: left;
  line-height: 30px;
  width: 60%; }

.c-order-totals__value {
  line-height: 30px;
  margin: 0;
  text-align: right; }

.c-panel {
  background: white;
  border: 1px solid rgba(192, 192, 192, 0.57);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05);
  padding: 20px;
  transition: opacity 0.2s ease-out;
  margin-bottom: 4px; }

.-content-inactive .c-panel {
  opacity: 0.5; }
  .-content-inactive .c-panel select,
  .-content-inactive .c-panel input {
    pointer-events: none; }

.-content-inactive .c-panel__active-content {
  display: none; }

@media only screen and (max-width: 991px) {
  .-content-inactive .c-panel {
    display: none; } }

.c-panel--with-header {
  padding-top: 0; }

.c-panel__header {
  background: #f3f3f3;
  margin: 0 -20px 20px -20px;
  padding: 10px 20px; }

.c-panel.-not-ready,
.-content-inactive .c-panel.-not-ready {
  opacity: 0; }

.c-select {
  position: relative; }

.c-select__select {
  background: transparent;
  border: 1px solid #dddddd;
  width: 100%;
  height: 40px;
  padding: 0 1em;
  -webkit-appearance: none; }

.c-select::after {
  position: absolute;
  top: 13px;
  right: 15px;
  transform: rotate(90deg);
  content: ">";
  display: inline-block;
  z-index: 1;
  width: 10px;
  pointer-events: none; }

/*
Based on html5doctor.com Reset Stylesheet v1.6.1
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  outline: 0;
  font-size: 14px;
  font-family: "Muli", sans-serif;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 22px; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

a {
  color: black;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

input,
select {
  vertical-align: middle; }

button:focus {
  outline: none; }

.grid-row:after {
  display: block;
  clear: both;
  content: ""; }

.grid-container {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  max-width: 1200px; }

.grid-row {
  margin-left: -1%;
  margin-right: -1%; }

[class^="grid-col-"] {
  float: left;
  padding-left: 1%;
  padding-right: 1%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.grid-col-1 {
  width: 8.33333%; }

.grid-col-2 {
  width: 16.66667%; }

.grid-col-3 {
  width: 25%; }

.grid-col-4 {
  width: 33.33333%; }

.grid-col-5 {
  width: 41.66667%; }

.grid-col-6 {
  width: 50%; }

.grid-col-7 {
  width: 58.33333%; }

.grid-col-8 {
  width: 66.66667%; }

.grid-col-9 {
  width: 75%; }

.grid-col-10 {
  width: 83.33333%; }

.grid-col-11 {
  width: 91.66667%; }

.grid-col-12 {
  width: 100%; }

@media only screen and (max-width: 991px) {
  .grid-col-m-1 {
    width: 8.33333%; }
  .grid-col-m-2 {
    width: 16.66667%; }
  .grid-col-m-3 {
    width: 25%; }
  .grid-col-m-4 {
    width: 33.33333%; }
  .grid-col-m-5 {
    width: 41.66667%; }
  .grid-col-m-6 {
    width: 50%; }
  .grid-col-m-7 {
    width: 58.33333%; }
  .grid-col-m-8 {
    width: 66.66667%; }
  .grid-col-m-9 {
    width: 75%; }
  .grid-col-m-10 {
    width: 83.33333%; }
  .grid-col-m-11 {
    width: 91.66667%; }
  .grid-col-m-12 {
    width: 100%; } }

@media only screen and (min-width: 992px) {
  .grid-col-d-1 {
    width: 8.33333%; }
  .grid-col-d-2 {
    width: 16.66667%; }
  .grid-col-d-3 {
    width: 25%; }
  .grid-col-d-4 {
    width: 33.33333%; }
  .grid-col-d-5 {
    width: 41.66667%; }
  .grid-col-d-6 {
    width: 50%; }
  .grid-col-d-7 {
    width: 58.33333%; }
  .grid-col-d-8 {
    width: 66.66667%; }
  .grid-col-d-9 {
    width: 75%; }
  .grid-col-d-10 {
    width: 83.33333%; }
  .grid-col-d-11 {
    width: 91.66667%; }
  .grid-col-d-12 {
    width: 100%; } }

.background-grey {
  background: #f2f2f2; }

.l-vertical-space {
  margin-top: 20px;
  margin-bottom: 20px; }

.l-vertical-space-bottom {
  margin-bottom: 20px; }

.u-text-centre {
  text-align: center; }

@media only screen and (max-width: 991px) {
  .l-m-hidden {
    display: none; } }

@media only screen and (min-width: 992px) {
  .l-d-hidden {
    display: none; } }

.c-panel {
  background: white;
  border: 1px solid rgba(192, 192, 192, 0.57);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05);
  padding: 20px;
  transition: opacity 0.2s ease-out;
  margin-bottom: 4px; }

.-content-inactive .c-panel {
  opacity: 0.5; }
  .-content-inactive .c-panel select,
  .-content-inactive .c-panel input {
    pointer-events: none; }

.-content-inactive .c-panel__active-content {
  display: none; }

@media only screen and (max-width: 991px) {
  .-content-inactive .c-panel {
    display: none; } }

.c-panel--with-header {
  padding-top: 0; }

.c-panel__header {
  background: #f3f3f3;
  margin: 0 -20px 20px -20px;
  padding: 10px 20px; }

.c-panel.-not-ready,
.-content-inactive .c-panel.-not-ready {
  opacity: 0; }

.c-step-tabs {
  background: white; }

@media only screen and (max-width: 991px) {
  .c-step-tabs {
    background: white;
    border: 1px solid rgba(192, 192, 192, 0.57);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.05), 0 2px 2px rgba(0, 0, 0, 0.05);
    padding: 0 20px; } }

.c-step-tab {
  background: white;
  height: 60px;
  line-height: 60px;
  text-align: center; }

.c-step-tab--clickable {
  text-decoration: underline;
  cursor: pointer; }

.c-step-tab.-active {
  border-bottom: 2px solid #231f20;
  font-weight: bold; }

.c-step-tab.-inactive {
  opacity: 0.5; }
